.ebook-viewer {
  border: 1px solid #ccc;
  background: #fff;
  -moz-box-shadow: inset 10px 0 20px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 10px 0 20px rgba(0, 0, 0, 0.1);
  box-shadow: inset 10px 0 20px rgba(0, 0, 0, 0.1);
  height: 400px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
}
.ebook-viewer.is-fullscreen {
  height: 100%;
}
.ebook-viewer-container {
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 20px;
  flex: 1;
  position: relative;
}
.ebook-viewer-divider {
  position: absolute;
  width: 1px;
  border-right: 1px #000 solid;
  height: calc(100% - 40px);
  z-index: 1;
  left: 50%;
  margin-left: -1px;
  top: 20px;
  opacity: 0.15;
  box-shadow: -2px 0 15px #000000;
}
.ebook-viewer-area {
  flex: 1;
  overflow: hidden;
  align-self: stretch;
  color: #000000 !important;
  font-family: serif !important;
}
.ebook-viewer-arrow,
.ebook-viewer-action {
  padding: 5px 18px;
}
.ebook-viewer-arrow::before,
.ebook-viewer-action::before {
  margin: 0;
}
.ebook-viewer-arrow.prev::before {
  content: '<';
}
.ebook-viewer-actions {
  background: #454661;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
}
.ebook-viewer-action {
  border-radius: 0;
}
.ebook-viewer-action.fullscreen::before {
  content: '';
  display: inline-block;
  width: 13px;
  height: 13px;
  background: url(img/ico-fullscreen-white.svg) center no-repeat;
  background-size: cover;
}
/*
#ebook-viewer .reader-wrap {
    position: relative;
    background: #eee;
    padding: 20px;
    max-width: 810px;
    margin: 40px auto
}

#ebook-viewer .arrow {
    position: absolute;
    top: 50%;
    margin-top: -32px;
    font-size: 64px;
    color: #E2E2E2;
    font-family: arial, sans-serif;
    font-weight: bold;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

#ebook-viewer .arrow:hover {
    color: #777
}

#ebook-viewer .arrow:active {
    color: #000
}

#ebook-viewer .prev {
    left: 40px
}

#ebook-viewer .next {
    right: 40px
}

#ebook-viewer .reader {
    width: 440px;
    height: 640px;
    overflow: hidden;
    border: 1px solid #ccc;
    margin: 20px auto;
    padding: 20px 20px;
    background: #fff;
    border-radius: 0 5px 5px 0;
    -moz-box-shadow: inset 10px 0 20px rgba(0,0,0,0.1);
    -webkit-box-shadow: inset 10px 0 20px rgba(0,0,0,0.1);
    box-shadow: inset 10px 0 20px rgba(0,0,0,0.1)}

#ebook-viewer .area {
    width: 400px;
    height: 600px;
    margin: 0 auto
}

@media screen and (max-width: 770px) {
    #ebook-viewer .reader {
        width: 330px;
        height: 510px;
    }
    #ebook-viewer .area {
        width: 330px;
        height: 510px;
    }
}
@media screen and (max-width: 590px) {
    #ebook-viewer .prev {
        left: 15px;
    }
    #ebook-viewer .next {
        right: 15px;
    }
}
@media screen and (max-width: 520px) {
    #ebook-viewer .reader {
        width: 220px;
        height: 320px;
        -moz-box-shadow: inset 5px 0 20px rgba(0,0,0,0.1);
        -webkit-box-shadow: inset 5px 0 20px rgba(0,0,0,0.1);
        box-shadow: inset 5px 0 20px rgba(0,0,0,0.1);
    }
    #ebook-viewer .area {
        width: 220px;
        height: 320px;
    }
}
@media screen and (max-width: 420px) {
    #ebook-viewer .prev {
        left: 20px;
        bottom: 20px;
        top: unset;
    }
    #ebook-viewer .next {
        right: 20px;
        bottom: 20px;
        top: unset;
    }
    #ebook-viewer .reader {
        margin-bottom: 40px;
    }
}
@media screen and (max-width: 350px) {
    #ebook-viewer .reader {
        width: 150px;
        height: 280px;
    }
    #ebook-viewer .area {
        width: 150px;
        height: 280px;
    }
}*/
